// extracted by mini-css-extract-plugin
export var pictureHeaderContainer = "services-module--pictureHeaderContainer--3FwdB";
export var pictureHeader = "services-module--pictureHeader--3YQJW";
export var pictureHeaderText = "services-module--pictureHeaderText--3WqHV";
export var weOfferContainer = "services-module--weOfferContainer--3CHOs";
export var weOfferBackgroundColor1 = "services-module--weOfferBackgroundColor1---wcot";
export var weOfferBackgroundColor2 = "services-module--weOfferBackgroundColor2--8EUx3";
export var weOfferContentContainer = "services-module--weOfferContentContainer---PCcQ";
export var weOfferTitle = "services-module--weOfferTitle--3NoqR";
export var weOfferCardContainer = "services-module--weOfferCardContainer--2BS7a";
export var weOfferCard = "services-module--weOfferCard--BJIVJ";
export var weOfferCardPic = "services-module--weOfferCardPic--2Us33";
export var weOfferCardTitle = "services-module--weOfferCardTitle--3wYxd";
export var weOfferCardDescription = "services-module--weOfferCardDescription--3yt-Z";
export var buttonLink = "services-module--buttonLink--15l09";
export var button = "services-module--button--1TJFy";
export var portfolioLinkContainer = "services-module--portfolioLinkContainer--1N7dY";
export var portfolioLink = "services-module--portfolioLink--2C9FC";
export var portfolioSubContainer = "services-module--portfolioSubContainer--1sbhk";
export var viewMorePic1 = "services-module--viewMorePic1--1nJsw";
export var viewMoreSpacer = "services-module--viewMoreSpacer--2cqwO";
export var viewMoreDescription = "services-module--viewMoreDescription--2A8GS";
export var viewMoreDescriptionText = "services-module--viewMoreDescriptionText--1ALBZ";
export var flowerIcon = "services-module--flowerIcon--3FQFF";
export var viewMorePic2 = "services-module--viewMorePic2--1mI_1";