import React from 'react';
import GlobalLayout from '../layouts/globalLayout';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql, Link } from "gatsby";
import * as styles from './services.module.css';
import { Button } from '@material-ui/core';

const imagesQuery = graphql`{
    pictureHeader: file(relativePath: {eq: "services/tableWithGlass.jpg"}) {
      childImageSharp {
        gatsbyImageData
      }
    },
    rentalsPic: file(relativePath: {eq: "services/Fave1.jpeg"}) {
        childImageSharp {
            gatsbyImageData(width: 300)
        }
    },
    eventStylingPic: file(relativePath: {eq: "services/Fave2.jpeg"}) {
        childImageSharp {
            gatsbyImageData(width: 300)
        }
    },
    customCommisioningPic: file(relativePath: {eq: "homePage/Rentals.jpg"}) {
        childImageSharp {
            gatsbyImageData(width: 300)
        }
    },
    viewMorePic1: file(relativePath: {eq: "services/viewMorePic1.jpeg"}) {
        childImageSharp {
            gatsbyImageData(width: 300, height: 300, transformOptions: {cropFocus: CENTER})
        }
    },
    viewMorePic2: file(relativePath: {eq: "services/viewMorePic2.jpeg"}) {
        childImageSharp {
            gatsbyImageData(width: 300, height: 300, transformOptions: {cropFocus: CENTER})
        }
    },
    flowerIcon: file(relativePath: {eq: "icons/flowerIcon.png"}) {
        childImageSharp {
            gatsbyImageData
        }
    },
  }`;

export default function Services() {
    const images = useStaticQuery(imagesQuery);

    return (
        <GlobalLayout bodyBackgroundColor="white">
            <div className={styles.pictureHeaderContainer}>
                <GatsbyImage image={images.pictureHeader.childImageSharp.gatsbyImageData} className={styles.pictureHeader} />
                <div className={styles.pictureHeaderText}>
                    adding vintage flair to life's milestones
                </div>
                {/* <GatsbyImage image={images.pictureHeader.childImageSharp.gatsbyImageData} className={styles.pictureHeader} /> */}
            </div>
            <div className={styles.weOfferContainer}>
                <div className={styles.weOfferBackgroundColor1} />
                <div className={styles.weOfferBackgroundColor2} />
                <div className={styles.weOfferContentContainer}>
                    <h1 className={styles.weOfferTitle}>WHAT WE OFFER</h1>
                    <div className={styles.weOfferCardContainer}>
                        <div className={styles.weOfferCard}>
                            <GatsbyImage image={images.rentalsPic.childImageSharp.gatsbyImageData} className={styles.weOfferCardPic} />
                            <h1 className={styles.weOfferCardTitle}>rentals</h1>
                            <p className={styles.weOfferCardDescription}>CHOOSE FROM OUR SELECTION OF FURNITURE, GLASSWARE, RUGS, AND MORE</p>
                        </div>
                        <div className={styles.weOfferCard}>
                            <GatsbyImage image={images.eventStylingPic.childImageSharp.gatsbyImageData} className={styles.weOfferCardPic} />
                            <h1 className={styles.weOfferCardTitle}>event styling</h1>
                            <p className={styles.weOfferCardDescription}>WE'LL WORK WITH YOU TO CURATE THE PERFECT AESTHETIC FOR YOUR EVENT</p>
                        </div>
                        <div className={styles.weOfferCard}>
                            <GatsbyImage image={images.customCommisioningPic.childImageSharp.gatsbyImageData} className={styles.weOfferCardPic} />
                            <h1 className={styles.weOfferCardTitle}>floral</h1>
                            <p className={styles.weOfferCardDescription}>CREATING ORIGINAL FLORAL ARRANGEMENTS FOR YOUR SPECIAL EVENT</p>
                        </div>
                    </div>
                </div>
                <Link to="/contact" className={styles.buttonLink}>
                    <Button className={styles.button}>CONTACT US TO GET STARTED</Button>
                </Link>
            </div>
            <div className={styles.portfolioLinkContainer}>
                <Link to="/services/portfolio" className={styles.portfolioLink}>
                    <div className={styles.portfolioSubContainer}>
                        <GatsbyImage image={images.viewMorePic1.childImageSharp.gatsbyImageData} className={styles.viewMorePic1} />
                        <div className={styles.viewMoreSpacer} />
                        <div className={styles.viewMoreDescription}>
                            <div className={styles.viewMoreDescriptionText}>
                                VIEW OUR RECENT PROJECTS
                            </div>
                            <GatsbyImage image={images.flowerIcon.childImageSharp.gatsbyImageData} className={styles.flowerIcon} />
                        </div>
                        <GatsbyImage
                            image={images.viewMorePic2.childImageSharp.gatsbyImageData}
                            className={styles.viewMorePic2}
                            style={{
                                position: 'absolute'
                            }}
                        />
                    </div>
                </Link>
            </div>
        </GlobalLayout>
    )
}